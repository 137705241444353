import { Modal } from "components/Modal";
import { TermsAndConditionsMainModalProps } from "./types";
import { useTermsAndConditions } from "hooks/compliance/useTermsAndConditions";
import { TermsAndConditionsUpdate } from "components/TermsAndConditionsMainModal/components/TermsAndConditionsUpdate";
import { TermsAndConditionsPlatform } from "components/TermsAndConditionsMainModal/components/TermsAndConditionsPlatform";
import { useGetUpdatedTermsAndConditions } from "hooks/compliance/useGetUpdatedTermsAndConditions";
import { useGetTermsAndConditions } from "hooks/compliance/useGetTermsAndConditions";
import { PRODUCT_NAME_TERMS_AND_CONDITION } from "@letsbit/malcolmlb";
import { useLocation } from "react-router-dom";

const DISABLED_TYC_PATHS = ["/logged/onboarding"];

const TermsAndConditionsMainModal: React.FC<
  TermsAndConditionsMainModalProps
> = () => {
  const {
    data: updatedTermsAndConditions,
    mutate: mutateTycUpdated,
    isLoading: isLoadingTycUpdated,
  } = useGetUpdatedTermsAndConditions();
  const {
    data: termsAndConditions,
    mutate: mutateTyc,
    isLoading: isLoadingTyc,
  } = useGetTermsAndConditions({
    product_name: PRODUCT_NAME_TERMS_AND_CONDITION.PLATFORM,
  });
  const { acceptTermsAndConditions } = useTermsAndConditions();
  const location = useLocation();

  const onFinishPlatform = async () => {
    if (termsAndConditions) {
      await Promise.all(
        termsAndConditions?.map((tyc) =>
          acceptTermsAndConditions({ version_control_id: tyc.id }),
        ),
      );
      await mutateTyc();
      await mutateTycUpdated();
    }
  };

  const onFinishUpdate = async () => {
    if (updatedTermsAndConditions) {
      await Promise.all(
        updatedTermsAndConditions?.map((tyc) =>
          acceptTermsAndConditions({ version_control_id: tyc.id }),
        ),
      );
      await mutateTyc();
      await mutateTycUpdated();
    }
  };

  const hasTyc = termsAndConditions && termsAndConditions.length > 0;
  const hasUpdatedTyc =
    updatedTermsAndConditions && updatedTermsAndConditions.length > 0;
  const isOpen =
    !isLoadingTyc &&
    !isLoadingTycUpdated &&
    (hasTyc || hasUpdatedTyc) &&
    !DISABLED_TYC_PATHS.find((path) => location.pathname.includes(path));

  return (
    <Modal
      isOpen={isOpen}
      withArrow={false}
      canDismiss={!isOpen}
      classNameModalWrapper="letsbit-tight-modal"
    >
      {!isLoadingTyc &&
        !isLoadingTycUpdated &&
        (hasTyc ? (
          <TermsAndConditionsPlatform
            termsAndConditions={termsAndConditions}
            onFinish={onFinishPlatform}
          />
        ) : (
          hasUpdatedTyc && (
            <TermsAndConditionsUpdate
              termsAndConditions={updatedTermsAndConditions}
              onFinish={onFinishUpdate}
            />
          )
        ))}
    </Modal>
  );
};

export default TermsAndConditionsMainModal;
