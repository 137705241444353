import { defineMessages } from "react-intl";

export const scope = "pages.OnBoarding.components.JuridicalCustomerForm";

export const messages = defineMessages({
  inputDescriptionNationalIdType: {
    id: `${scope}.inputDescriptionNationalIdType`,
    defaultMessage: "Número de identificación Tributaria ({national_id_type}) ",
  },
  companyPlaceholderNationalIdType: {
    id: `${scope}.companyPlaceholderNationalIdType`,
    defaultMessage: "Ingrese el {national_id_type} de su empresa",
  },
  continueButton: {
    id: `${scope}.continueButton`,
    defaultMessage: "CONTINUAR",
  },
  warning: {
    id: `${scope}.warning`,
    defaultMessage:
      "¡Atención! El número de identificación tributaria no podrá ser editado posteriormente",
  },
  termsAndConditions: {
    id: `${scope}.termsAndConditions`,
    defaultMessage:
      "Al presionar “Continuar” estarás aceptando nuestros legales de: {br} {link}",
  },
  linkText: {
    id: `${scope}.linkText`,
    defaultMessage: "términos y condiciones",
  },
});
