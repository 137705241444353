import { Entity, HubCurrencies } from "@letsbit/malcolmlb";
import { CurrencySelectorValues } from "./constants";
import { Wallet } from "hooks/user/useWallets";
import { Country } from "pages/PublicRoutes/SignUpRoutes/Enterprise/EnterpriseCountryPage";

export const filterBanks = (c: Entity, searchString: string) =>
  c.name.toLowerCase().includes(searchString.toLowerCase()) ||
  c.name.includes(searchString.toLowerCase());

export const filterCurrencies = (c: HubCurrencies, searchString: string) =>
  c.name.toLowerCase().includes(searchString.toLowerCase()) ||
  c.id.includes(searchString.toLowerCase()) ||
  c.ticker_id.includes(searchString.toLowerCase());

export const filterCountries = (c: Country, searchString: string) =>
  c.label.toLowerCase().includes(searchString.toLowerCase()) ||
  c.value.includes(searchString.toLowerCase());

export const filterByTag = (c: HubCurrencies, tag: CurrencySelectorValues) =>
  c.tags.includes(tag);

export const filterWallets = (wallet: Wallet, searchString: string) =>
  filterCurrencies(wallet as HubCurrencies, searchString);
