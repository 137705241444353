import { useAuthSWR } from "hooks/utils/useAuthSWR";
import { ApiContext } from "providers/ApiProvider";
import { useContext } from "react";

const SWR_GET_UPDATED_TERMS_AND_CONDITIONS_KEY =
  "get-updated-terms-and-conditions";

export const useGetUpdatedTermsAndConditions = () => {
  const { memberInstance } = useContext(ApiContext);

  const { data, error, mutate, isLoading } = useAuthSWR(
    [SWR_GET_UPDATED_TERMS_AND_CONDITIONS_KEY],
    async () => {
      const result = await memberInstance.hub.getUpdatedTermsAndConditions();
      return result?.data;
    },
  );

  return {
    data,
    error,
    mutate,
    isLoading,
  };
};
