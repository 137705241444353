import { defineMessages } from "react-intl";

export const scope = "components.TermsAndConditionsUpdate";

export const messages = defineMessages({
  title: {
    id: `${scope}.title`,
    defaultMessage: "Nuevos términos {br} y condiciones",
  },
  productMessage: {
    id: `${scope}.productMessage`,
    defaultMessage:
      "Queremos que tengas la mejor experiencia utilizando nuestra aplicación,  por eso queremos avisarte que realizamos cambios en nuestros términos y condiciones.",
  },
  boldMessage: {
    id: `${scope}.boldMessage`,
    defaultMessage:
      "Podés ver todos los legales en la sección {br}Perfil > Términos y condiciones.",
  },
  accept: {
    id: `${scope}.accept`,
    defaultMessage: "ACEPTAR",
  },
  back: {
    id: `${scope}.back`,
    defaultMessage: "VOLVER",
  },
  termsAndConditions: {
    id: `${scope}.termsAndConditions`,
    defaultMessage:
      "Al presionar “Continuar” estarás aceptando nuestros legales de: {br} {link}",
  },
  linkText: {
    id: `${scope}.linkText`,
    defaultMessage: "términos y condiciones",
  },
  continue: {
    id: `${scope}.continue`,
    defaultMessage: "CONTINUAR",
  },
});
