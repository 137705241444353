import React, { useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";

import Icon from "assets/images/phone-with-success-icon.svg";

import { TermsAndConditionsPlatformProps } from "./types";
import { messages } from "./messages";
import InfoScreen from "components/InfoScreen";
import { ReactComponent as InfoIcon } from "assets/images/icons/info.svg";

export const TermsAndConditionsPlatform: React.FC<
  TermsAndConditionsPlatformProps
> = ({ termsAndConditions, onFinish }) => {
  const { formatMessage } = useIntl();
  const [loading, setLoading] = useState(false);

  return (
    <InfoScreen
      picture={Icon}
      chatButton
      info={
        <div className="flex flex-col gap-4">
          <h2 className="text-center text-[32px]/[38.4px] font-bold text-[#5F259F]">
            <FormattedMessage {...messages.title} values={{ br: <br /> }} />
          </h2>
          <h3 className="text-center text-sm leading-4">
            {formatMessage(messages.productMessage)}
          </h3>
          <h3 className="text-center text-sm font-bold leading-4">
            <FormattedMessage
              {...messages.boldMessage}
              values={{ br: <br /> }}
            />
          </h3>
        </div>
      }
      filledButton={{
        text: formatMessage(messages.continue),
        onSubmit: async () => {
          try {
            setLoading(true);
            await onFinish();
          } finally {
            setLoading(false);
          }
        },
        isLoading: loading,
        dataCypressId: "accept-tyc-updates",
      }}
      footer={
        <div
          className={
            "mb-[14px] flex items-center justify-center text-xs font-semibold text-[--milkylb-color-neutral-400]"
          }
        >
          <InfoIcon className="shrink-0" />
          <p className="ml-2">
            <FormattedMessage
              {...messages.termsAndConditions}
              values={{
                br: <br />,
                link: termsAndConditions.map((tyc) => (
                  <a
                    className="text-[--milkylb-color-secondary-200] underline-offset-1"
                    href={tyc.url}
                    target="_blank"
                  >
                    {tyc.friendly_name}
                  </a>
                )),
              }}
            />
          </p>
        </div>
      }
    />
  );
};
