import { useContext, useState } from "react";
import { TermsAndConditionsBody } from "@letsbit/malcolmlb";
import { ApiContext } from "providers/ApiProvider";
import { defineMessages, useIntl } from "react-intl";
import { useNotification } from "hooks/utils/useNotification";
import { NotificationStatus } from "components/Notification/types";

export const scope = "hook.useTermsAndConditions";

export const messages = defineMessages({
  acceptTermsAndConditionsFailedNotificationMessage: {
    id: `${scope}.acceptTermsAndConditionsFailedNotificationMessage`,
    defaultMessage:
      "No pudimos aceptar el término, intenta nuevamente en unos segundos",
  },
  acceptTermsAndConditionsSuccessNotificationMessage: {
    id: `${scope}.acceptTermsAndConditionsSuccessNotificationMessage`,
    defaultMessage: "Término y condicion aceptado con éxito",
  },
  notificationTitle: {
    id: `${scope}.notificationTitle`,
    defaultMessage: "Términos y condiciones",
  },
});

export const useTermsAndConditions = () => {
  const { memberInstance } = useContext(ApiContext);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { formatMessage } = useIntl();
  const { showNotification } = useNotification();

  const acceptTermsAndConditions = async (params: TermsAndConditionsBody) => {
    try {
      setIsLoading(true);

      const data =
        await memberInstance.compliance.acceptTermsAndConditions(params);
      showNotification({
        title: formatMessage(messages.notificationTitle),
        message: formatMessage(
          messages.acceptTermsAndConditionsSuccessNotificationMessage,
        ),
        status: NotificationStatus.SUCCESS,
      });
      return data;
    } catch (error) {
      showNotification({
        title: formatMessage(messages.notificationTitle),
        message: formatMessage(
          messages.acceptTermsAndConditionsFailedNotificationMessage,
        ),
        status: NotificationStatus.ERROR,
      });
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  return {
    acceptTermsAndConditions,
    isLoading,
  };
};
