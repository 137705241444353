import { GetTermsAndConditionsParams } from "@letsbit/malcolmlb";
import { useAuthSWR } from "hooks/utils/useAuthSWR";
import { ApiContext } from "providers/ApiProvider";
import { useContext } from "react";

const SWR_GET_TERMS_AND_CONDITIONS_KEY = "get-terms-and-conditions";

export const useGetTermsAndConditions = (
  params?: GetTermsAndConditionsParams,
) => {
  const { memberInstance } = useContext(ApiContext);

  const { data, error, mutate, isLoading } = useAuthSWR(
    params ? [SWR_GET_TERMS_AND_CONDITIONS_KEY, params] : null,
    async () => {
      const result = await memberInstance.hub.getTermsAndConditions(params);
      return result.data;
    },
  );

  return {
    data,
    error,
    mutate,
    isLoading,
  };
};
